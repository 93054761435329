import React, { useContext, useEffect, useState } from "react";
import { Slide } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Modal from '@material-ui/core/Modal';
import { ArrowLeft } from "@material-ui/icons";
import { ResentOtp, VerifyOtpET } from "../service";
import { useHistory } from "react-router";
import { DataContext } from "./Data.Context";
import './contextCss.css';
const AlertDataContext = React.createContext({});

const AlertDataContextProvider = ({ children }) => {
    const [error, setError] = useState(false);
    const [errorstr, setErrorstr] = useState("");
    const [info, setInfo] = useState(false);
    const [infostr, setInfostr] = useState("");
    const [loading, setLoading] = useState(false);
    const [OTPModal, setOTPModal] = useState(false);
    const [countdown, setcountdown] = useState(60);
    const [currentScreen, setCurrentScreen] = useState("");
    const [otp, setotp] = useState("");
    const [Eotp, seteotp] = useState("");

    const { data } = useContext(DataContext);

    // DynamicModel States
    const [DynamicModel, setDynamicModel] = useState(false);
    const [DynamicModelBody, setDynamicModelBody] = useState();
    const [DynamicModelHeading, setDynamicModelHeading] = useState("");
    const [DynamicModelShowClose, setDynamicModelShowClose] = useState(false);

    const history = useHistory();

    const DynamicModelShow = (heading, body, close) => {
        setDynamicModel(true);
        setDynamicModelBody(body);
        setDynamicModelShowClose(close);
        setDynamicModelHeading(heading);
    };

    const setmOTP = async (screen) => {
        setLoading(true);
        await ResentOtp(data);
        setCurrentScreen(screen);
        setOTPModal(true);
        setLoading(false);
        startcountdown();
    };

    const showError = (ErrorString) => {
        setErrorstr(ErrorString);
        setError(true);
        setTimeout(() => setError(false), 3000);
    };

    const showInfo = (InfoString) => {
        setInfostr(InfoString);
        setInfo(true);
        setTimeout(() => setInfo(false), 3000);
    };

    const startcountdown = () => {
        let timer = setInterval(() => {
            setcountdown((prev) => {
                if (prev <= 0) {
                    clearInterval(timer);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const submit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const resp = await VerifyOtpET(data, otp, Eotp);
        setLoading(false);
        if (resp.StatusCode === 0) {
            window.location.reload();
        } else {
            showError("Some Error occurs. Please check your details");
        }
    };

    const DynamicModelHide = () => {
        setDynamicModel(false);
    };

    return (
        <AlertDataContext.Provider value={{
            showInfo,
            showError,
            setLoading,
            setmOTP,
            DynamicModelShow,
            DynamicModelHide
        }}>
            {loading && (
                <div className="loading-overlay">
                    <div className="loader"></div>
                    <h3 className="loading-text">Please wait.</h3>
                </div>
            )}

            {error && (
                <div className="alert-container">
                    <Slide direction="right" in={true}>
                        <Alert severity="error">{errorstr}</Alert>
                    </Slide>
                </div>
            )}

            {info && (
                <div className="alert-container">
                    <Slide direction="right" in={true}>
                        <Alert severity="info">{infostr}</Alert>
                    </Slide>
                </div>
            )}

            {OTPModal && (
                <Modal open>
                    <div className="otp-modal">
                        <form onSubmit={submit}>
                            <h2>Session Expired</h2>
                            <p>Enter the OTP code sent to your Email address.</p>
                            <input className="input-text" name="eotp" autoFocus onChange={(e) => seteotp(e.target.value)} required />
                            <p className="otp-timer">OTP expires in 00:{countdown < 10 ? "0" + countdown : countdown}</p>
                            <button type="submit" className="submit-button">
                                Next
                                <ArrowLeft />
                            </button>
                        </form>
                    </div>
                </Modal>
            )}

            {DynamicModel && (
                <Modal open>
                    <div className="dynamic-modal">
                        <h2>{DynamicModelHeading}</h2>
                        <div className="dynamic-modal-body">
                            {DynamicModelBody}
                            {DynamicModelShowClose && (
                                <button
                                    className="close-button"
                                    onClick={DynamicModelHide}
                                >
                                    Close
                                </button>
                            )}
                        </div>
                    </div>
                </Modal>
            )}

            {children}
        </AlertDataContext.Provider>
    );
};

export {
    AlertDataContext,
    AlertDataContextProvider
};
