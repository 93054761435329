import 'bootstrap/dist/css/bootstrap.css';
import './generated-styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { applyPolyfills, defineCustomElements } from "fas-web-ui-components/cameraModule/loader";
applyPolyfills().then(() => {
  defineCustomElements(window);
});
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`
                   default-src 'self' data:;
                  script-src 'self' https://www.google.com/recaptcha/api.js https://www.gstatic.com 'sha256-47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' 'sha256-AbpHGcgLb+kRsJGnwFEktk7uzpZOCcBY74+YBdrKVGs=' 
                  'sha256-C9X6+bMRIg5XqBmThFrfn61nsSc8vRtxEFra25DVJTI=' 'sha256-8oQu3cOkcEPQbgEW6XuFmh5d3hFhtkrs45AyatY9jRE=' 'sha256-tekUYy0NkA+O1VOKFNT8dKWreGklo3ejoHu6gC++VBI='
                  'sha256-Arr8PLNstSXxH0AnTNUHamt+sB/F1xmKootnJE4+jkY=' 'sha256-eR82ZNLO4zNp7LKuH60OlQG2kma8diWMPpjEYWNG6j0=' 'sha256-FBfiEN2yBupb9BvasrYtZBd0YIJFy+VuMmOR3AmCh5w='
                  'sha256-EVkKHeUyPfCZ+GQjOI03X6eJW1J4TFzwD7tOo902coI=' 'sha256-HDUiONb3XAqpgUb8U8+xVIQKzNqPB+jRWyNicRG/8qY=' 'sha256-zPHwR+tCRmhFI3cjR62nnjjfNAVY9+uiDu2sgECapKY='
                  'sha256-nFd7FEtAAZ6ktMWT4+eFCSiBZhqnPqjCwr3q2943f0Q=' 'sha256-RoR6Za57/SikQwe95ogWk9fRaYyip5/y9qBGjWdC8zg=' 'sha256-tYSUmkuamUIlbC4yzfqoe4lrUktAnlaiKzUxXPVMFBQ='
                  'sha256-Um1thQnf7x5rvrT6tAK6wAnQDttWsUFkHp1EiY45EYY=' 'sha256-pF9nO8baXA7XfS61pxlwYY3qcrfbunOhFDr+4yj8tjY=' 'sha256-QnrMyjfsByUaKZYr7Rn8a58vRUu7AR91yhoeFn6zA5w='
                  'sha256-swHUg/wpif/Vm/Dgc4K85nmmddxvR5Ln/ppkq3s0M1s=' 'sha256-zF1vKq74YNuzoQE3DIAMgn2mcAxbKNWo07R2d3bAp4Y=' 'sha256-CyXpdnggljMsT/35DIKy/Y+iZ1ehfwWTMwcf1ZSh4qY='
                  'sha256-xwP+p0xZiRAvnatuzp6uJY4aHarChsfeKaWA/01pOaU=' 'sha256-ZO1vb9tgYGmsFj3xX/1PxApDxYS6jS6Zj7DmLnyG2Bg=' 'sha256-/Tnn7AFmHW7dOod9k3eKAiKxvaxbS6Ner9hAbUm2xGQ='
                  'sha256-ftX0P3wIMMaenoJ/l0O0zynvvtBLk0dGERBz9EESxr0=' 'sha256-vPCXN5s0p666D2UdpBJpy2dwEhYH+if7MO7CefihWfc=' 'sha256-xeZtI2j7QXHV1DBPTfXv0LL+khzrB0Vh9y5qKRcysOw='
                  'sha256-ykH9CljjFNmIz3PVFBXSDvDMvcID1hR7ikrAvlTYG4E=' 'sha256-2cYUo89nLwLWZINExvpMR1jppYHHdQCe7hwq7nsAvM0=' 'sha256-ms3+BRCXLguboKH/TxIQpi3Fb7lf4ceGmUY96HdMhQw='
                  'sha256-1RssRSHixK01htxxe9M2kmCSxBO+K4OuceVNyiyurUI=' 'sha256-PTId0jNkVzigoWkCl50rTLw5d4AdfFCqKWhMronTqlU=' 'sha256-YfHFAcfiF79jBm/9U/2NIuvacbreORVpxHAg06lBUjs='
                  'sha256-5keE3z/nQQ7dO4CkZ+uylN/xVnSlyRt4jvMeqtCKRYA=' 'sha256-G1M1XGH37wGDjC3+s/I8dEvPVzDxvDO0UO/TBsFAbhM=' 'sha256-NROHST3QL4o80x39IWkWzkwVWAE7DnDEarMt4JByl3Y='
                  'sha256-Tem3jOEej46mgLtrjMdBHIQ4WwMhWDslgqvx7B52BYo=' 'sha256-i2fd3DSdhQb5sHOLusFg3+1qKhrlQnWjFLRLtcAFz4M=' 'sha256-qKsfBiDGXU2ivghXnSLfffD5+MpB3WsKk0/js71o//0=' data:;
                  connect-src 'self' https://api.ipify.org blob: data:;
                  style-src 'self' 'sha256-47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' 'sha256-AbpHGcgLb+kRsJGnwFEktk7uzpZOCcBY74+YBdrKVGs=' 
                  'sha256-C9X6+bMRIg5XqBmThFrfn61nsSc8vRtxEFra25DVJTI=' 'sha256-8oQu3cOkcEPQbgEW6XuFmh5d3hFhtkrs45AyatY9jRE=' 'sha256-tekUYy0NkA+O1VOKFNT8dKWreGklo3ejoHu6gC++VBI='
                  'sha256-Arr8PLNstSXxH0AnTNUHamt+sB/F1xmKootnJE4+jkY=' 'sha256-eR82ZNLO4zNp7LKuH60OlQG2kma8diWMPpjEYWNG6j0=' 'sha256-FBfiEN2yBupb9BvasrYtZBd0YIJFy+VuMmOR3AmCh5w='
                  'sha256-EVkKHeUyPfCZ+GQjOI03X6eJW1J4TFzwD7tOo902coI=' 'sha256-HDUiONb3XAqpgUb8U8+xVIQKzNqPB+jRWyNicRG/8qY=' 'sha256-zPHwR+tCRmhFI3cjR62nnjjfNAVY9+uiDu2sgECapKY='
                  'sha256-nFd7FEtAAZ6ktMWT4+eFCSiBZhqnPqjCwr3q2943f0Q=' 'sha256-RoR6Za57/SikQwe95ogWk9fRaYyip5/y9qBGjWdC8zg=' 'sha256-tYSUmkuamUIlbC4yzfqoe4lrUktAnlaiKzUxXPVMFBQ='
                  'sha256-Um1thQnf7x5rvrT6tAK6wAnQDttWsUFkHp1EiY45EYY=' 'sha256-pF9nO8baXA7XfS61pxlwYY3qcrfbunOhFDr+4yj8tjY=' 'sha256-QnrMyjfsByUaKZYr7Rn8a58vRUu7AR91yhoeFn6zA5w='
                  'sha256-swHUg/wpif/Vm/Dgc4K85nmmddxvR5Ln/ppkq3s0M1s=' 'sha256-zF1vKq74YNuzoQE3DIAMgn2mcAxbKNWo07R2d3bAp4Y=' 'sha256-CyXpdnggljMsT/35DIKy/Y+iZ1ehfwWTMwcf1ZSh4qY='
                  'sha256-xwP+p0xZiRAvnatuzp6uJY4aHarChsfeKaWA/01pOaU=' 'sha256-ZO1vb9tgYGmsFj3xX/1PxApDxYS6jS6Zj7DmLnyG2Bg=' 'sha256-/Tnn7AFmHW7dOod9k3eKAiKxvaxbS6Ner9hAbUm2xGQ='
                  'sha256-ftX0P3wIMMaenoJ/l0O0zynvvtBLk0dGERBz9EESxr0=' 'sha256-vPCXN5s0p666D2UdpBJpy2dwEhYH+if7MO7CefihWfc=' 'sha256-xeZtI2j7QXHV1DBPTfXv0LL+khzrB0Vh9y5qKRcysOw='
                  'sha256-ykH9CljjFNmIz3PVFBXSDvDMvcID1hR7ikrAvlTYG4E=' 'sha256-2cYUo89nLwLWZINExvpMR1jppYHHdQCe7hwq7nsAvM0=' 'sha256-ms3+BRCXLguboKH/TxIQpi3Fb7lf4ceGmUY96HdMhQw='
                  'sha256-1RssRSHixK01htxxe9M2kmCSxBO+K4OuceVNyiyurUI=' 'sha256-PTId0jNkVzigoWkCl50rTLw5d4AdfFCqKWhMronTqlU=' 'sha256-YfHFAcfiF79jBm/9U/2NIuvacbreORVpxHAg06lBUjs='
                  'sha256-5keE3z/nQQ7dO4CkZ+uylN/xVnSlyRt4jvMeqtCKRYA=' 'sha256-G1M1XGH37wGDjC3+s/I8dEvPVzDxvDO0UO/TBsFAbhM=' 'sha256-NROHST3QL4o80x39IWkWzkwVWAE7DnDEarMt4JByl3Y='
                  'sha256-Tem3jOEej46mgLtrjMdBHIQ4WwMhWDslgqvx7B52BYo=' 'sha256-i2fd3DSdhQb5sHOLusFg3+1qKhrlQnWjFLRLtcAFz4M=' 'sha256-qKsfBiDGXU2ivghXnSLfffD5+MpB3WsKk0/js71o//0='; 
                  font-src 'self' https://fonts.gstatic.com;
                  img-src 'self' data:;
                  frame-src 'self' https://www.google.com https://bilchat.braincrop.net;
                  base-uri 'self';
                  object-src 'none';
                  frame-ancestors 'none';
              `}
        ></meta>
      </Helmet><BrowserRouter basename={baseUrl}>

        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
  , rootElement);
