import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Main from '../../Assets/MainC.jpg'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { ApiDataContext } from "../Context/Api.context";
import { getProductType } from "../service";
import { AlertDataContext } from "../Context/Alert.context";
import { screen } from "./screen";
const AccountOption = () => {
    const { data, setData } = useContext(DataContext);
    const { setActive, setComplete, AddSteps } = useContext(StepsContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const { DynamicModelShow } = useContext(AlertDataContext)
    const [options, setoptions] = useState([])
    const [sel, setsel] = useState(false)
    const history = useHistory();
    const enable = false;// 0 < screen[sessionStorage.getItem("CurrentScreen")] ? true : false;
    const [layout, setLayout] = useState(false)
    useEffect(() => {
        setActive("Account Details");
        setData({ ...data, SideImage: Main });
        async function getProduct() {
            if (ApiData.Producttype === undefined || ApiData.AlreadyAccountsList === undefined) {
                var arr = await getProductType();
                debugger
                arr = arr.ResponseObject;
                setoptions([...arr.Accountlist]);
                setApiData({ ...ApiData, Producttype: arr.Accountlist, AlreadyAccountsList: arr.AlreadyAccountsList });
                // setData({ ...data, option: arr[0].AccountType })
            } else {
                setoptions([...ApiData.Producttype]);
            }
            setLayout(true)
        }
        //getProduct();
    }, [])
    useEffect(() => {
        setsel(data.option?.AccountType ? false : true);
    }, [data]);

    const showFDAModel = (data) => {
        var t = <div style={{ height: "60vh", overflow: "auto" }}>
            <div className="Q" style={{ margin: 10 }}>{data.Description}</div>
            Currencies:
            <ul>
                {data.Currency.map(o => (
                    <li>{o}</li>
                ))}
            </ul>
            Features:
            <ul>
                {data.LongDescription.map(o => (
                    <li>{o}</li>
                ))}
            </ul>
            {data.Note !== "" ?
                <p className="Q">Note:{data.Note}</p>
                : <></>}
        </div>;
        DynamicModelShow(data.AccountType + "", t, true);
    }
    const AlreadyHavethisProduct = (data) => {
        var t = <div style={{ overflow: "auto" }}>
            <div className="Q" style={{ margin: 10 }}>
                You have already submited an application with this account type.
            </div></div>;
        DynamicModelShow("Already Registered" + "", t, true);
    }
    return (
        <Animatedpage submit={() => {
            if (!sel) {
                //setComplete("Banking Type");
                history.push("otherdetails")
            } else {
                alert("Please select a account type.");
            }
        }} useauth={true} pop={() => setLayout(true)}>
            {layout ?
                <Grid item xs={12} id="s1q2">
                    <div style={{ width: "100%", marginLeft: "1%", marginRight: "1%", alignSelf: "center" }} className={"rowmob"}>

                        {/* <p style={{ marginTop: 30 }} className="Qhelper" >Question <b>2</b></p> */}
                        <h1 className="Q" style={{ fontSize: 20, marginTop: 20 }}>Select your preferred account type</h1>
                        {ApiData.Producttype.map(p => (
                            <div key={p.Id} className={"Carddiv" + (data?.option?.Id === p.Id ? " Active" : "")} onClick={() => {
                                if (ApiData?.AlreadyAccountsList !== undefined && ApiData?.AlreadyAccountsList.findIndex(o => o.ProductTypeId === p.Id || o.Productgroup === 1) !== -1) {
                                    AlreadyHavethisProduct(p);
                                    return;
                                }

                                if (!enable) {
                                    setData({ ...data, option: { ...p }, type: p.Type })
                                }
                                showFDAModel(p);
                                setsel(false);
                            }}>

                                <p className="AccountTypeName" style={{ fontWeight: "bold" }}>{p.AccountType}
                                    {data?.option?.Id === p.Id ? <CheckBox className="CardCheck" style={{ color: "var(--primary)", height: 40 }} /> : null}
                                </p>
                                <ul style={{ textAlign: "start", marginLeft: -15 }}>
                                    {p.ShortDescription.map(s => (
                                        <li>{s}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        <br />

                        <button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("/")}>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button>
                        <button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                            disabled={sel}
                        >Next
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </div>
                </Grid>
                : <></>}
        </Animatedpage>
    )

}
export default AccountOption;